import styled from 'styled-components';

export const QrHowToItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const QrHowToItemNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D9E1E7;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-right: 10px;
`;

export const QrHowToItemText = styled.div`
  font-size: 12px;
  padding-top: 4px;
  color: #000000;
`;

export const QrHowToWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
