import React, { FC } from 'react';

export const ReceiptIcon: FC<{ className?: string; }> = ({ className }) => {
	return <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1364_2410)">
			<path d="M9.5 7H15.5M9.5 11H15.5M13.5 15H15.5M5.5 21V5C5.5 4.46957 5.71071 3.96086 6.08579 3.58579C6.46086 3.21071 6.96957 3 7.5 3H17.5C18.0304 3 18.5391 3.21071 18.9142 3.58579C19.2893 3.96086 19.5 4.46957 19.5 5V21L16.5 19L14.5 21L12.5 19L10.5 21L8.5 19L5.5 21Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_1364_2410">
				<rect width="24" height="24" fill="white" transform="translate(0.5)"/>
			</clipPath>
		</defs>
	</svg>

}

