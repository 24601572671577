import styled from 'styled-components';
import { ArrowTopIcon } from '../../../core/icons/ArrowTopIcon';
export const OrderDescriptionWrapper = styled.div``;
export const OrderDescriptionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
`;


export const OrderDescriptionTitle = styled.div`
  font-size: 20px;
`;

export const OrderDescriptionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowTopIconWrapper = styled(ArrowTopIcon)<{ isRotate: boolean; }>`
  transform: ${props => !props.isRotate ? 'rotate(180deg);' : 'rotate(0);'}
`;
