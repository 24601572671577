import React, { FC } from 'react';
import styled from 'styled-components';

export const PaymentCancelLink = styled.a`
  border: 2px solid #381DDB;
  color: #381DDB;
  border-radius: 8px;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 0 19px;
  cursor: pointer;
  text-decoration: none;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
`;
export const PaymentCancel: FC<{ children: React.ReactNode; href?: string; }> = ({ children, href }) => {
	return <PaymentCancelLink href={href}>{children}</PaymentCancelLink>
}
