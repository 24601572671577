import styled from 'styled-components';
import { OrderDescriptionIcon } from '../core/icons/OrderDescriptionIcon';
import { OrderGiftIcon } from '../core/icons/OrderGiftIcon';
import { QrCodeIcon } from '../core/icons/QrCodeIcon';

export const PaymentStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: #F3F3F6;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PaymentTitle = styled.div<{ isEndState: boolean; }>`
  color: #14213D;
  font-size: 30px;
  line-height: 22px;
  font-weight: 500;
  width: 100%;
  margin: 30px 0;
  padding-left: ${props => props.isEndState ? '8px' : '16px'};
`;

export const PaymentHeader = styled.div<{ isEndState: boolean; }>`
  height: 60px;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  max-width: 848px;
  width: ${props => props.isEndState ? '414px' : '100%'};
  padding-left: ${props => props.isEndState ? '0' : '16px'};
  
`;

export const PaymentContentCenter = styled.div<{ isEndState: boolean; }>`
  display: flex;
  align-items: center;
  max-width: 848px;
  flex-direction: column;
  width: 100%;

  @media all and (min-width: 768px) {
    width: ${props => props.isEndState ? '414px' : '100%'};
  }
`;

export const PaymentItemWrapper = styled.div`
	margin-bottom: 20px;
`;

export const PaymentContent = styled.div`
  width: 100%;
  max-width: 848px;
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;

  @media all and (min-width: 768px) {
    background-color: initial;
	display: grid;
    grid-template-columns: 414px 414px;
	gap: 20px;
  }
`;


export const FooterCopyright = styled.div`
  margin-top: 32px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0 24px;
`;

export const OrderDescriptionIconStyled = styled(OrderDescriptionIcon)`
  margin-right: 10px;
  margin-bottom: 4px;
`;

export const OrderGiftIconStyled = styled(OrderGiftIcon)`
  margin-right: 10px;
  margin-bottom: 4px;
`;

export const QrCodeIconStyled = styled(QrCodeIcon)`
  margin-right: 10px;
  margin-bottom: 4px;
`;

export const QrCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaymentBackdrop = styled.div`
  display: flex;
  flex-direction: column;
  @media all and (min-width: 768px) {
    background-color: #fff;
    padding: 22px 16px;
    border-radius: 10px;
  }
`;

export const PaymentBackdropRight = styled.div`

`;

export const SbpButtonWrapper = styled.div`
  @media all and (min-width: 768px) {
    display: none;
  }
`;

export const OrderRulesWrapper = styled.div`
  @media all and (min-width: 768px) {
    order: 4;
  }
`;

export const PaymentEndStateWrapper = styled.div`
  width: calc(100% - 20px);
  background-color: #fff;
  padding: 22px 16px;
  border-radius: 10px;
`;

export const LoaderWrapper = styled.div`
  width: calc(100% - 20px);
  background-color: #fff;
  padding: 22px 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaymentStateIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const PaymentReceipt = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 30px;
  font-size: 20px;
  color: #000;

  svg {
    margin-right: 10px;
  }
`;

export const PaymentStateTitle = styled.div<{ lowMargin?: boolean }>`
  margin-bottom: 30px;
  margin-top: ${props => props.lowMargin ? 0 : '30px'};
  width: 100%;
  text-align: center;
  font-size: 20px;
`;

export const PaymentUnavailableCancelWrapper = styled.div`
	margin-top: 30px;
`;

// @xs-value: 320px;
// @sm-value: 767px;
// @md-value: 991px;
// @lg-value: 1279px;
// @xl-value: 1280px;
//
// @xs: ~"all and (max-width: @{xs-value})";
// @sm: ~"all and (max-width: @{sm-value})";
// @md: ~"all and (max-width: @{md-value})";
// @lg: ~"all and (max-width: @{lg-value})";
// @xl: ~"all and (min-width: @{xl-value})";
