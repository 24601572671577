import React, { FC } from 'react';

export const QrCodeIcon: FC<{ className?: string; }> = ({ className }) => {
	return <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1294_1390)">
			<path d="M4 5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5V9C10 9.26522 9.89464 9.51957 9.70711 9.70711C9.51957 9.89464 9.26522 10 9 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M7 17V17.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14 5C14 4.73478 14.1054 4.48043 14.2929 4.29289C14.4804 4.10536 14.7348 4 15 4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V9C20 9.26522 19.8946 9.51957 19.7071 9.70711C19.5196 9.89464 19.2652 10 19 10H15C14.7348 10 14.4804 9.89464 14.2929 9.70711C14.1054 9.51957 14 9.26522 14 9V5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M7 7V7.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4 15C4 14.7348 4.10536 14.4804 4.29289 14.2929C4.48043 14.1054 4.73478 14 5 14H9C9.26522 14 9.51957 14.1054 9.70711 14.2929C9.89464 14.4804 10 14.7348 10 15V19C10 19.2652 9.89464 19.5196 9.70711 19.7071C9.51957 19.8946 9.26522 20 9 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V15Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17 7V7.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14 14H17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20 14V14.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14 14V17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14 20H17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M17 17H20" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20 17V20" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_1294_1390">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
}

