import React, { FC } from 'react';

export const OrderDescriptionIcon: FC<{ className?: string; }> = ({ className }) => {
	return <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1238_3223)">
			<path d="M20 12.8222V7.5L12 3L4 7.5V16.5L12 21" stroke="black" stroke-width="2" strokeLinecap="round" stroke-linejoin="round"/>
			<path d="M12 12L20 7.5" stroke="black" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
			<path d="M12 12V21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12 12L4 7.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.5664 18C14.5664 18.7956 14.8825 19.5587 15.4451 20.1213C16.0077 20.6839 16.7708 21 17.5664 21C18.3621 21 19.1251 20.6839 19.6877 20.1213C20.2503 19.5587 20.5664 18.7956 20.5664 18C20.5664 17.2044 20.2503 16.4413 19.6877 15.8787C19.1251 15.3161 18.3621 15 17.5664 15C16.7708 15 16.0077 15.3161 15.4451 15.8787C14.8825 16.4413 14.5664 17.2044 14.5664 18Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M19.7666 20.2002L21.5666 22.0002" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_1238_3223">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>

}
