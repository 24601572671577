import React, { FC } from 'react';
import {
	OrderAmountWrapper, OrderAmountShopName,
	OrderAmountValue
} from './OrderNumber.styled';

type OrderAmountProps = {
	shopName: string;
	id: number;
};
export const OrderNumber: FC<OrderAmountProps> = ({shopName, id}) => {
	return <OrderAmountWrapper>
		<OrderAmountShopName> {shopName} </OrderAmountShopName>
		<OrderAmountValue>Заказ №{id}</OrderAmountValue>
	</OrderAmountWrapper>
}
