import React, { FC } from 'react';
import {
	OrderAmountWrapper, OrderAmountShopName,
	OrderAmountValue,
	OrderAmountSubtitle,
	OrderFullAmount
} from './OrderAmount.styled';

type OrderAmountProps = {
	amount: number;
	shopName: string;
	discountAmount?: number;
};
export const OrderAmount: FC<OrderAmountProps> = ({amount, shopName, discountAmount}) => {
	return <OrderAmountWrapper>
		<OrderAmountShopName> {shopName} </OrderAmountShopName>
		<OrderAmountValue> {discountAmount ? discountAmount : amount} ₽</OrderAmountValue>
		{discountAmount && <OrderFullAmount>{ amount } ₽</OrderFullAmount>}
		<OrderAmountSubtitle>Сумма полной оплаты</OrderAmountSubtitle>
	</OrderAmountWrapper>
}
