import React, { FC, useState } from 'react';
import {
	OrderDescriptionHeader,
	OrderDescriptionWrapper,
	OrderDescriptionTitle,
	OrderDescriptionHeaderLeft, ArrowTopIconWrapper
} from './OrderDescription.styled';

export const OrderItemToggle: FC<{
	title: string;
	icon: React.ReactNode;
	content: React.ReactNode;
	startIsOpen?: boolean;
	closedTitle?: string;
	isHideToggle?: boolean;
}> = ({title, icon, content, startIsOpen = true, closedTitle, isHideToggle}) => {
	const [isOpen, setIsOpen] = useState(startIsOpen);

	const onOpen = () => {
		if (isHideToggle) {
			return;
		}
		setIsOpen(!isOpen);
	}

	return <OrderDescriptionWrapper>
		<OrderDescriptionHeader onClick={onOpen}>
			<OrderDescriptionHeaderLeft>
				{icon}
				<OrderDescriptionTitle>{closedTitle && !isOpen ? closedTitle : title}</OrderDescriptionTitle>
			</OrderDescriptionHeaderLeft>
			{!isHideToggle && <ArrowTopIconWrapper isRotate={isOpen}/>}
		</OrderDescriptionHeader>

		{isOpen && <div>
			{content}
        </div>}
	</OrderDescriptionWrapper>;
}
