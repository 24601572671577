import React, { FC } from 'react';

export const SuccessPromoIcon: FC<{ className?: string; }> = ({ className }) => {
	return <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.88281 13.0547C4.88281 14.0396 5.07681 15.0149 5.45372 15.9248C5.83063 16.8348 6.38307 17.6615 7.07951 18.358C7.77595 19.0544 8.60274 19.6069 9.51269 19.9838C10.4226 20.3607 11.3979 20.5547 12.3828 20.5547C13.3677 20.5547 14.343 20.3607 15.2529 19.9838C16.1629 19.6069 16.9897 19.0544 17.6861 18.358C18.3826 17.6615 18.935 16.8348 19.3119 15.9248C19.6888 15.0149 19.8828 14.0396 19.8828 13.0547C19.8828 12.0698 19.6888 11.0945 19.3119 10.1846C18.935 9.27462 18.3826 8.44783 17.6861 7.75139C16.9897 7.05495 16.1629 6.5025 15.2529 6.12559C14.343 5.74868 13.3677 5.55469 12.3828 5.55469C11.3979 5.55469 10.4226 5.74868 9.51269 6.12559C8.60274 6.5025 7.77595 7.05495 7.07951 7.75139C6.38307 8.44783 5.83063 9.27462 5.45372 10.1846C5.07681 11.0945 4.88281 12.0698 4.88281 13.0547Z"
			  stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M9.88281 13.0544L11.5495 14.721L14.8828 11.3877" stroke="white" strokeWidth="2"
			  strokeLinecap="round" strokeLinejoin="round"/>
	</svg>

}
