import styled from 'styled-components';

export const OrderRulesWrapper = styled.div`
  background-color: #F3F3F6;
  padding: 24px 0;
  font-size: 12px;
  text-align: center;
  margin: 22px -16px;

  @media all and (min-width: 768px) {
    margin: 22px -16px -22px -16px;
  }

  & a {
    color: #111827;
    text-decoration: underline;
  }
`;
