import qs from 'query-string';

export enum HTTP_METHODS {
	GET = 'GET',
	POST = 'POST',
	PATCH = 'PATCH',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export class API {
	static BASE_URL = '/api/v1';

	private static async request<T>(
		method: HTTP_METHODS,
		url: string,
		body?: unknown,
		params?: Record<string, any>,
		headers?: Record<string, string>,
		signal?: AbortSignal
	): Promise<T> {
		const _headers = headers ? headers : {};
		_headers['Content-Type'] = 'application/json';
		const _url = params ? API.getUrlWithParams(API.BASE_URL + url, params) : API.BASE_URL + url;
		const response = await fetch(_url, {
			headers: _headers,
			body: method !== HTTP_METHODS.GET ? JSON.stringify(body) : undefined,
			method,
			signal
		});

		return this.handleResponse<T>(response, { method });
	}

	private static async handleResponse<T>(response: Response, request: RequestInit): Promise<T> {
		const contentType = response.headers.get('Content-Type');
		if (response.ok) {
			if (request.method === HTTP_METHODS.DELETE) {
				return {} as T;
			}

			const isJson = contentType === 'application/json';
			return isJson ? response.json().catch(() => null) : response.text();
		}

		if (response.status >= 500) {
			throw response.statusText;
		}

		if (response.status >= 400 && response.status < 500) {
			throw contentType === 'application/json' ? await response.json() : { message: await response.text() };
		}

		throw contentType === 'application/json' ? await response.json() : await response.text();
	}

	static async formData<T>(method: HTTP_METHODS, url: string,
							 data: Record<string, string|Blob>,
							 headers?: Record<string, string>): Promise<T> {
		const _headers = headers ? headers : {};
		const fd = new FormData();
		Object.keys(data).forEach((key) => fd.append(key, data[key]));
		const _url = API.BASE_URL + url;
		const response = await fetch(_url, {
			headers: _headers,
			body: method !== HTTP_METHODS.GET ? fd : undefined,
			method,
		});

		return this.handleResponse<T>(response, { method });
	}

	static getUrlWithParams(url: string, query: Record<string, string>): string {
		return `${url}?${qs.stringify(query)}`;
	}

	static async post<T>(url: string, body?: unknown, headers?: Record<string, string>, signal?: AbortSignal): Promise<T> {
		return API.request<T>(HTTP_METHODS.POST, url, body, undefined, headers, signal);
	}

	static async put<T>(url: string, body: unknown, headers?: Record<string, string>, signal?: AbortSignal): Promise<T> {
		return API.request<T>(HTTP_METHODS.PUT, url, body, undefined, headers, signal);
	}

	static async patch<T, R>(url: string, body: T, headers?: Record<string, string>, signal?: AbortSignal): Promise<R> {
		return API.request<R>(HTTP_METHODS.PATCH, url, body, undefined, headers, signal);
	}

	static async delete<T>(url: string, headers?: Record<string, string>, signal?: AbortSignal): Promise<void> {
		await API.request<T>(HTTP_METHODS.DELETE, url, null, undefined, headers, signal);
	}

	static async get<T>(url: string, params?: any, headers?: Record<string, string>, signal?: AbortSignal): Promise<T> {
		return API.request<T>(HTTP_METHODS.GET, url, null, params, headers, signal);
	}
}
