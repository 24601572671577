import { API } from '../core/api';

type OrderStatus = 'paid' | 'new';

type PaymentRaw = {
	russian_trade_name: string;
	buyer_description: string;
	amount: number;
	discount_amount: number;
	qr_link: string;
	qr_image: string;
	nspk_link: string;
	order_status: OrderStatus;
	receipt_link: string;
	order_id: number;
	is_redirect: string;
	redirect_url: string;
	discount: number;
	promocode: string;
};

export class Payment {
	constructor(private payment: PaymentRaw) {}

	get isPaid() {
		return this.payment.order_status === 'paid';
	}

	get isNew() {
		return this.payment.order_status === 'new';
	}

	get amount() {
		return this.payment.amount;
	}

	get qr_image() {
		return this.payment.qr_image;
	}

	get russian_trade_name() {
		return this.payment.russian_trade_name;
	}
	get buyer_description() {
		return this.payment.buyer_description;
	}
	get discount_amount() {
		return this.payment.discount_amount;
	}

	get discount() {
		return this.payment.discount;
	}

	get promocode() {
		return this.payment.promocode;
	}

	get qr_link() {
		return this.payment.qr_link;
	}
	get nspk_link() {
		return this.payment.nspk_link;
	}
	get order_status() {
		return this.payment.order_status;
	}

	get receipt_link() {
		return this.payment.receipt_link;
	}

	get order_id() {
		return this.payment.order_id;
	}

	get is_redirect() {
		return this.payment.is_redirect;
	}

	get redirect_url() {
		return this.payment.redirect_url;
	}
}


export class PaymentApi extends API {
	static getState(idOrder: number) {
		return this.get<{ state: string; }>(`/ca/tsp/order/payment/state?idOrder=${idOrder}`)
	}

	static sendPromocode(idOrder: number, promocode: string) {
		return this.get<{ isValid: boolean; }>(`/ca/promocode/${idOrder}?promo_code=${promocode}`);
	}

	static getPayment(idOrder: number, redirectUrl: string, promocode?: string) {
		let url = `/ca/tsp/order/payment?idOrder=${idOrder}`;
		if (promocode) {
			url += `&promocode=${promocode}`;
		}

		if (redirectUrl) {
			url += `&redirect_url=${redirectUrl}`;
		}

		return this.get<Payment>(url)
	}
}
