import React, { FC } from 'react';
import { SbpButtonSbp, SbpButtonStyled } from './SbpButton.styled';


export const SbpButton: FC<{ link: string }> = ({ link }) => {
	return <SbpButtonStyled href={link}>
		<span>Оплатить</span>
		<svg width="35" height="43" viewBox="0 0 35 43" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.381836 9.44141L5.47868 18.5517V24.1087L0.387798 33.2011L0.381836 9.44141Z" fill="#5B57A2"></path>
			<path d="M19.9514 15.2361L24.7273 12.3089L34.5015 12.2998L19.9514 21.2133V15.2361Z" fill="#D90751"></path>
			<path d="M19.9248 9.38774L19.9518 21.4494L14.843 18.3104V0.280273L19.9248 9.38774Z" fill="#FAB718"></path>
			<path d="M34.5019 12.3002L24.7274 12.3093L19.9248 9.38774L14.843 0.280273L34.5019 12.3002Z" fill="#ED6F26"></path>
			<path d="M19.9518 33.2517V27.3997L14.843 24.3203L14.8458 42.3683L19.9518 33.2517Z" fill="#63B22F"></path>
			<path d="M24.7156 30.351L5.47832 18.5517L0.381836 9.44141L34.4811 30.3391L24.7156 30.351Z" fill="#1487C9"></path>
			<path d="M14.8467 42.3679L19.9519 33.2513L24.7159 30.3508L34.4814 30.3389L14.8467 42.3679Z" fill="#017F36"></path>
			<path d="M0.387939 33.201L14.8848 24.3204L10.011 21.3301L5.47882 24.1086L0.387939 33.201Z" fill="#984995"></path>
		</svg>
		<SbpButtonSbp>сбп</SbpButtonSbp>
	</SbpButtonStyled>
}
