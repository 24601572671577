import React from 'react';
import { QrHowToItem, QrHowToItemNum, QrHowToItemText, QrHowToWrapper } from './QrHowTo.styled';
export const QrHowToComponent = () => {
	return <QrHowToWrapper>
		<QrHowToItem>
			<QrHowToItemNum>1</QrHowToItemNum>
			<QrHowToItemText>Наведите камеру телефона на QR-код</QrHowToItemText>
		</QrHowToItem>

		<QrHowToItem>
			<QrHowToItemNum>2</QrHowToItemNum>
			<QrHowToItemText>На экране появится выбор приложения банка. <br/> Следуйте дальнейшим инструкциям в телефоне. <br/> Если не сработало, переходите к шагу 3</QrHowToItemText>
		</QrHowToItem>

		<QrHowToItem>
			<QrHowToItemNum>3</QrHowToItemNum>
			<QrHowToItemText>Откройте приложение банка в телефоне</QrHowToItemText>
		</QrHowToItem>

		<QrHowToItem>
			<QrHowToItemNum>4</QrHowToItemNum>
			<QrHowToItemText>Перейдите в раздел «Платежи»</QrHowToItemText>
		</QrHowToItem>

		<QrHowToItem>
			<QrHowToItemNum>5</QrHowToItemNum>
			<QrHowToItemText>Выберите пункт «Оплата по QR-коду» и отсканируйте QR-код</QrHowToItemText>
		</QrHowToItem>

		<QrHowToItem>
			<QrHowToItemNum>6</QrHowToItemNum>
			<QrHowToItemText>Заказ сформируется в течение минуты после оплаты</QrHowToItemText>
		</QrHowToItem>
	</QrHowToWrapper>
}
