import styled from 'styled-components';

export const SbpButtonStyled = styled.a`
  color: #fff;
  border-radius: 8px;
  background-color: #2A0856;
  font-size: 20px;
  font-weight: 500;
  padding: 0 19px;
  cursor: pointer;
  text-decoration: none;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  width: auto;
  margin-bottom: 30px;
  
  svg {
	margin-left: 6px;
  }
`;

export const SbpButtonSbp = styled.span`
	font-weight: bold;
`;
