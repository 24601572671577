import styled from 'styled-components';
export const OrderAmountWrapper = styled.div`
  padding: 12px 14px 12px 14px;
  gap: 9px;
  border-radius: 7px;
  background-color: #F1F5F7;
  margin-bottom: 20px;
`;

export const OrderAmountShopName = styled.div`
	font-size: 20px;
  font-weight: 500;
`;

export const OrderAmountValue = styled.div`
  font-size: 42px;
  line-height: 55px;
  font-weight: 500;
`;

export const OrderAmountSubtitle = styled.div`
	font-size: 12px;
`;

export const OrderFullAmount = styled.div`
  text-decoration: line-through;
  font-size: 26px;
  font-weight: bold;
`;
